export const ROLES = {
  ADMIN: 'admin',
  FINANCE: 'finance',
  SUPPORT: 'support',
}

const USER_PERMISSIONS = {
  USER_VIEW_LIST: 'user_view_list',
  USER_VIEW_DETAILS: 'user_view_details',
  USER_EDIT: 'user_edit',
}

const ATTENTION_PERMISSIONS = {
  ATTENTION_VIEW_USER_DUPLICATES: 'attention_view_user_duplicates',
  ATTENTION_VIEW_INVALID_TRANSACTIONS: 'attention_view_invalid_transactions',
  ATTENTION_VIEW_NON_MATCHING_IBAN: 'attention_view_non_matching_iban',
  ATTENTION_VIEW_WRONG_DETAILS: 'attention_view_wrong_details',
  ATTENTION_VIEW_REJECTED_APPLICATIONS: 'attention_view_rejected_applications',
}

const BACKOFFICE_PERMISSIONS = {
  BACKOFFICE_VIEW_EMPLOYEES_LIST: 'backoffice_view_employees_list',
  BACKOFFICE_EDIT_EMPLOYEE: 'backoffice_edit_employee',
  BACKOFFICE_CREATE_EMPLOYEE: 'backoffice_create_employee',
}

const AUDIT_TRAIL_PERMISSIONS = {
  AUDIT_TRAIL_VIEW: 'audit_trail_view',
}

const MERCHANT_PERMISSIONS = {
  MERCHANT_VIEW_LIST: 'merchant_view_list',
  MERCHANT_VIEW_BALANCE_LIST: 'merchant_view_balance_list',
  MERCHANT_VIEW_DETAILS: 'merchant_view_details',
  MERCHANT_VIEW_FUNDS_ADJUSTMENTS_LIST: 'merchant_view_funds_adjustments_list',
  MERCHANT_VIEW_BALANCE_ALERTS: 'merchant_view_balance_alerts',
  MERCHANT_EDIT_CONFIGS: 'merchant_edit_configs',
  MERCHANT_CREATE: 'merchant_create'
}

const TOOLS_PERMISSIONS = {
  TOOLS_TEST_TRANSACTION_CREATE: 'tools_test_transaction_create',
  TOOLS_WHITELISTING: 'tools_whitelisting',
}

const TRANSACTION_PERMISSIONS = {
  TRANSACTION_VIEW_LIST: 'transaction_view_list',
  TRANSACTION_VIEW_DETAILS: 'transaction_view_details',
}

const REPORT_PERMISSIONS = {
  REPORT_VIEW_DAILY_PERFORMANCE: 'report_view_daily_performance',
  REPORT_VIEW_FINANCE_PAYORO: 'report_view_finance_payoro',
}

const FINANCE_TOOL_PERMISSIONS = {
  FINANCE_TOOL_VIEW_LEDGER_ADJUSTMENT: 'finance_tool_ledger_adjustment',
  FINANCE_TOOL_VIEW_STATEMENTS_UPLOAD: 'finance_tool_statements_upload',
  FINANCE_TOOL_VIEW_MERCHANT_FINANCES: 'finance_tool_merchant_finances',
  FINANCE_TOOL_VIEW_MERCHANT_REPORT_MAINTENANCE: 'finance_tool_merchant_report_maintenance',
  FINANCE_TOOL_VIEW_RETURNS_MAPPING: 'finance_tool_returns_mapping',
}

export const PERMISSIONS = {
  ...USER_PERMISSIONS,
  ...ATTENTION_PERMISSIONS,
  ...BACKOFFICE_PERMISSIONS,
  ...AUDIT_TRAIL_PERMISSIONS,
  ...MERCHANT_PERMISSIONS,
  ...TOOLS_PERMISSIONS,
  ...TRANSACTION_PERMISSIONS,
  ...REPORT_PERMISSIONS,
  ...FINANCE_TOOL_PERMISSIONS,
}

export const rolesMapping = {
  [ROLES.ADMIN]: Object.values(PERMISSIONS),
  [ROLES.FINANCE]: [
    ...Object.values(FINANCE_TOOL_PERMISSIONS),
  ],
  [ROLES.SUPPORT]: [
    ...Object.values(USER_PERMISSIONS),
    ...Object.values(TRANSACTION_PERMISSIONS),
    ...Object.values(ATTENTION_PERMISSIONS),
    MERCHANT_PERMISSIONS.MERCHANT_VIEW_LIST,
    MERCHANT_PERMISSIONS.MERCHANT_VIEW_BALANCE_LIST,
    MERCHANT_PERMISSIONS.MERCHANT_VIEW_DETAILS,
    MERCHANT_PERMISSIONS.MERCHANT_VIEW_FUNDS_ADJUSTMENTS_LIST,
    MERCHANT_PERMISSIONS.MERCHANT_VIEW_BALANCE_ALERTS,
    FINANCE_TOOL_PERMISSIONS.FINANCE_TOOL_VIEW_LEDGER_ADJUSTMENT,
    FINANCE_TOOL_PERMISSIONS.FINANCE_TOOL_VIEW_STATEMENTS_UPLOAD,
    FINANCE_TOOL_PERMISSIONS.FINANCE_TOOL_VIEW_RETURNS_MAPPING,
  ]
}

// Order is important for determining nearest home route
export const HOME_ROUTE_BY_ROLE = {
  [ROLES.ADMIN]: { name: 'user' },
  [ROLES.SUPPORT]: { name: 'user' },
  [ROLES.FINANCE]: { name: 'finance-tool' },
}

