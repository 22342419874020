export default (api) => ({
  /**
   * @param {Object} body - The request body containing the date range for the report
   * @param {Number} body.year - Year of the report period
   * @param {Number} body.month - Month of the report period
   */
  triggerGeneration: (body) => api.post('/reports/merchant-finance/trigger', body),
  regenerateReport: (body, merchantId) => api.post(`/reports/merchant-finance/trigger/${merchantId}`, body),
  getGeneralInfo: () => api.get('/reports/merchant-finance/info'),
  getReportsInfo: (merchantId, params) => api.get(`/reports/merchant-finance/single/merchant/${merchantId}`, { params }),
  getAllReportsInfo: (params) => api.get('/reports/merchant-finance', { params }),
  downloadPdfReport: (reportId) => api.get(`/reports/merchant-finance/download/${reportId}/pdf`, {
    responseType: 'blob',
  }),
  downloadXlsxReport: (reportId) => api.get(`/reports/merchant-finance/download/${reportId}/xslx`, {
    responseType: 'blob',
  }),
  deleteReport: (reportId) => api.delete(`/reports/merchant-finance/delete/${reportId}`),
  publishReport: (reportId) => api.post(`/reports/merchant-finance/publish/${reportId}`),
  uploadReport: (id, body) => api.post(`/reports/merchant-finance/upload/${id}`, body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
})
